export default {
  storyTale1:
    "Hope Labs was founded during the most challenging and unpredictable year in recent history. The events of 2020 have thoroughly tested the potential of technology and innovation. They have also proven how people can be selfless and kind when the world most needs it. ",
  storyTale2:
    'As a team of scientists and engineers, technology and innovation has always represented a source of "hope" that the future will be better than the past. To begin working towards a better future all we needed was a catalyst to bring us together and the turbulent year of 2020 sent us a powerful "call to action".',
  storyTale3:
    "From day one, we have been working tirelessly to collect data on all kinds of companies, ventures and associations that aim to have a net-positive impact on the community. Today, we use this knowledge to help selected charities and social enterprises communicate more efficiently within the real and virtual ecosystems. Our end goal is to support outstanding ventures by inspiring the members of their community to get involved in activities with a positive impact. Together we can fast-forward to the future!",
  peopleIntro:
    "The team behind Hope Labs consist of a great mix of talented people with a proven track record, research skills and a shared commitment to innovate the way charities and social enterprises share their stories.",
  valueIntro:
    'As an organisation, we have defined a set of shared beliefs to guide our behaviours and bind us together. These beliefs are the cornerstones of our value system (we call it "PEACE"). We are extremely proud of our values because they communicate who we are and how we do business.',
  V1: {
    title: "Passion",
    description:
      "We are a high-energy and fast-moving team. Passion is our fuel and we use it to engage and inspire one another.",
  },
  V2: {
    title: "Education",
    description:
      "Our collaborative and open work culture fosters growth and learning. We believe personal growth generates innovation.",
  },
  V3: {
    title: "Action",
    description:
      "We take action! We are never afraid of unexpected outcomes and see every challenge as an opportunity to prove ourselves.",
  },
  V4: {
    title: "Courtesy",
    description:
      "We want to show respect and gratitude at every interaction with others inside and outside our company, and the environment.",
  },
  V5: {
    title: "Excellence",
    description:
      "We set ambitious goals and achieve them as a team. Relentless improvement is a shared responsibility.",
  },
};
