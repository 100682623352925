import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Card from "../components/card/cardFactory";

import People from "../documents/descriptions/peopleDescription";
import companyDescription from "../documents/descriptions/companyDescription";

const DirectorsGallery = () => {
  return (
    <section role="main">
      <Row className={`presentation justify`}>
        <h1 className={"mt-2em"}>Story</h1>
        <p>{companyDescription.storyTale1}</p>
        <p>{companyDescription.storyTale2}</p>
        <p>{companyDescription.storyTale3}</p>
      </Row>
      <Row className={`presentation justify`}>
        <h1 className={"mt-2em"}>Directors</h1>
        <p>{companyDescription.peopleIntro}</p>
      </Row>
      <Row className="md-3 mt-2em">
        <Col md>
          <Card
            title={People.P1.Name}
            subtitle={People.P1.Role}
            image={People.P1.Avatar}
            text={People.P1.Long}
            url={People.P1.url}
            facebook={People.P1.facebook}
            linkedin={People.P1.linkedin}
            instagram={People.P1.instagram}
          />
        </Col>
        <Col md>
          <Card
            title={People.P2.Name}
            subtitle={People.P2.Role}
            image={People.P2.Avatar}
            text={People.P2.Long}
            url={People.P2.url}
            facebook={People.P2.facebook}
            linkedin={People.P2.linkedin}
            instagram={People.P2.instagram}
          />
        </Col>
        <Col md>
          <Card
            title={People.P3.Name}
            subtitle={People.P3.Role}
            image={People.P3.Avatar}
            text={People.P3.Long}
            url={People.P3.url}
            facebook={People.P3.facebook}
            linkedin={People.P3.linkedin}
            instagram={People.P3.instagram}
          />
        </Col>
      </Row>
    </section>
  );
};

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "images/covers/Group_Silhouettes_Dusk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Our Team"
        description={
          "Hope Labs was founded by three friends with similar values and the desire to give back to the community."
        }
      />
      <section role="main">
        <Row className={`imageBox no-margin`}>
          <div className={`imageFilter d-none d-sm-block`}></div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Artistic cover image"
          />
          <h1 className={`overlayTitle d-none d-sm-block`}>
            {"Meet the team"}
          </h1>
        </Row>
        <DirectorsGallery />
      </section>
    </Layout>
  );
};

export default About;
