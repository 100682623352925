import aP1 from "../../images/team/MarcoGhilardi_Avatar.jpg";
import aP2 from "../../images/team/GiacomoGiudice_Avatar.jpg";
import aP3 from "../../images/team/IreneZanon_Avatar.jpg";

// Add no more than 2/3 external links each
export default {
  P1: {
    Name: "Marco Ghilardi",
    Avatar: aP1,
    Role: "Co-Founder & Executive Chairman",
    Short:
      "Social Entrepreneur and Formula One Engineer. Marco believes in a future with information availability and transparency. He has an extremely curious nature and is a keen cyclist.",
    Long:
      "Social Entrepreneur and Formula One Engineer. Marco believes in a future with information availability and transparency. He has an extremely curious nature and is a keen cyclist.",
    url: "https://quasiscience.com",
    linkedin: "https://www.linkedin.com/in/marco-ghilardi/",
    facebook: "",
    instagram: "",
  },
  P2: {
    Name: "Giacomo Giudice",
    Avatar: aP2,
    Role: "Co-Founder & Technology Director",
    Short:
      "PhD student in Quantum Physics. Giacomo likes solving big problems with computers and creating automated tasks. He is passionate about climbing and backcountry skiing.",
    Long:
      "PhD student in Quantum Physics. Giacomo likes solving big problems with computers and creating automated tasks. He is passionate about climbing and backcountry skiing.",
    url: "https://quasiscience.com",
    linkedin: "https://www.linkedin.com/in/giacomogiudice/",
    facebook: "",
    instagram: "",
  },
  P3: {
    Name: "Irene Zanon",
    Avatar: aP3,
    Role: "Co-Founder & Operations Director",
    Short:
      "PhD student in Nuclear Physics, graduated in Harp. Irene thinks that the world is too vast and interesting to only choose one road. She dreams of visiting Greenland, one day.",
    Long:
      "PhD student in Nuclear Physics, graduated in Harp. Irene thinks that the world is too vast and interesting to only choose one road. She dreams of visiting Greenland, one day.",
    url: "https://quasiscience.com",
    linkedin: "https://www.linkedin.com/in/irene-zanon-a87a68167/",
    facebook: "",
    instagram: "",
  },
};
